<template>
    <div class="tuwen-list">
        <div class="header">
            <div class="title">图文</div>
            <div class="more">查看更多</div>
        </div>
        <div class="list">
            <div v-for="course,i in courses" :key="'course-item'+i" class="item" @click="$router.push({name:'course',params:{type: 'article',id: course.id}})">
                <div class="left">
                    <div class="title">
                        {{ course.title }}
                    </div>
                    <div class="views">{{course.views}}阅读</div>
                </div>
                <img :src="courseImage(course.image)" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import cfg from "../config.js";
    
    export default {
        name: 'TuwenList',
        data(){
            return {}
        },
        props: {
            courses: {
                type: Array
            }
        },
        methods: {
            courseImage(url){
                if(url){
                    return /^http/.test(url) ? url : cfg._ASSETS_HOST + url;
                }else{
                    return require("../assets/logo.png");
                }
            }
        }
    }
</script>

<style lang="less" scoped>
.tuwen-list {
    background: #fff;
    padding: 15px;
    margin-bottom: 15px;
    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .title {
            font-weight: bold;
        }
        .more {
            font-size: 14px;
            color: #999;
        }
    }
    .item {
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
        img {
            width: 128px;
        }
        .left {
            flex: 1;
            padding-right: 15px;
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .title {
                font-weight: bold;
            }
            .views {
                color: #999;
                font-size: 14px;
            }
        }
    }
} 
</style>