<template>
    <div class="zhibo-list">
        <div class="header">
            <div class="title">直播课程</div>
            <div class="more">查看更多</div>
        </div>
        <van-row class="list" type="flex" gutter="15" justify="space-between">
            <van-col v-for="course,i in courses" :key="'course-item'+i" span="12" @click="$router.push({name:'course',params:{type: 'live',id: course.id}})">
                <div class="item">
                    <img :src="courseImage(course.image)" alt="">
                    <div class="title">
                        {{course.title}}
                    </div>
                </div>
            </van-col>
        </van-row>
    </div>
</template>

<script>
import cfg from "../config.js";
    export default {
        name: 'ZhiboList',
        data(){
            return {}
        },
        props: {
            courses: {
                type: Array
            }
        },
        methods: {
            courseImage(url){
                if(url){
                    return /^http/.test(url) ? url : cfg._ASSETS_HOST + url;
                }else{
                    return require("../assets/logo.png");
                }
            }
        }
    }
</script>

<style lang="less" scoped>
.zhibo-list {
    background: #fff;
    padding: 15px;
    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 15px;
        .title {
            font-weight: bold;
        }
        .more {
            font-size: 14px;
            color: #999;
        }
    }
    .item {
        background: #f6f6f6;
        margin-bottom: 15px;
        border-radius: 7px;
        img {
            height: 108px;
            width: 100%;
        }
        .title {
            padding: 5px 0 15px;
            font-size: 14px;
        }
    }
} 
</style>