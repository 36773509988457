<template>
    <div class="lubo-list">
        <div class="header">
            <div class="title">录播课程</div>
            <div class="more">查看更多</div>
        </div>
        <div class="list">
            <div v-for="course,i in courses" class="item" :key="'lubo-item'+i" @click="$router.push({name:'course',params:{type: 'media',id: course.id}})">
                <img :src="courseImage(course.image)" alt="">
                <div class="right">
                    <div class="title">
                        {{course.title}}
                    </div>
                    <div class="tag-price">
                        <div class="tag">使用教程</div>
                        <div v-if="course.price==0" class="price hot">
                            <span >免费</span>
                        </div>
                        <div v-else class="price hot">
                            <span class="small">¥</span><span class="red">{{course.price}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import cfg from "../config.js";
    export default {
        name: 'LuboList',
        data(){
            return {}
        },
        props: {
            courses: {
                type: Array
            }
        },
        methods: {
            courseImage(url){
                if(url){
                    return /^http/.test(url) ? url : cfg._ASSETS_HOST + url;
                }else{
                    return require("../assets/logo.png");
                }
            }
        }
    }
</script>

<style lang="less" scoped>
.lubo-list {
    background: #fff;
    padding: 15px;
    margin-bottom: 15px;
    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .title {
            font-weight: bold;
        }
        .more {
            font-size: 14px;
            color: #999;
        }
    }
    .item {
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
        img {
            width: 128px;
        }
        .right {
            flex: 1;
            padding-left: 15px;
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .title {
                font-weight: bold;
            }
            .tag-price {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                .tag {
                    color: #999;
                    font-size: 14px;
                }
                .free {
                    color: #5ac57f;
                    font-size: 14px;
                    font-weight: bold;
                }
                .hot {
                    color: #eb645e;
                    font-weight: bold;
                    .small {
                        font-size: 14px;
                        padding-right: 3px;
                    }
                }
            }
        }
    }
} 
</style>