<template>
    <div class="footer">
      <img class="logo" src="@/assets/logo.png" alt="">
    </div>
</template>
<script>

export default {
  name: 'Footer',
  data(){
    return {

    }
  },
  methods:{
    
  }
}
</script>
<style lang="less" scoped>
.footer{
  padding-bottom: 50px;
  color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: .2;
  .logo {
    width: 72px;
    filter: grayscale(100%);
  }
  
}
</style>
