<template>
    <div class="banners">
        <van-swipe :autoplay="3000" indicator-color="white">
            <van-swipe-item class="van-swipe-item" v-for="(image, index) in banners" :key="index">
                <img v-lazy="image" />
            </van-swipe-item>
        </van-swipe>
    </div>
    
</template>

<script>
    // import {store} from 'xijs';
    export default {
        name: "Banner",
        props:{},
        data() {
            return {
                banners: [
                    require('@/assets/imgs/banner1.png'),
                    require('@/assets/imgs/banner1.png'),
                ]
            }
        },
        mounted() {
            // this.getBanners();
        },
        methods: {
            // handleGo(item) {
            //     if(item.type == 0){
            //         return;
            //     }
            //     if(item.type==1){
            //         this.$router.push({path: item.ext_id});
            //     }else{
            //         window.open(item.ext_id, '_blank');
            //     }
            // },
            // getBanners(){
            //     let _data = store.get('home_banners');
            //     if(_data.value){
            //         this.banners = JSON.parse(_data.value);
            //     }else{
            //         if(this.loading) return;
            //         this.loading = true;
            //         let _this = this;
            //         this.$http.call(this.$http.api.banners).then(res=>{
            //             console.log(res);
            //             let resp = res.data;
            //             if(resp.code=='200'){
            //                 _this.banners = resp.data;
            //                 store.set('home_banners', JSON.stringify(resp.data), Date.now()+601000);
            //             }else{
            //                 _this.$message.error(resp.data);
            //             } 
            //         },function(res){
            //             console.log('something goes wrong...');
            //             console.log(res);
            //         }).then(()=>{
            //             _this.loading =false;
            //         });
            //     }
            // },
        },
        beforeDestroy() {
            
        },
    }
</script>

<style lang="less" scoped>
.banners{
    background: #fff;
    padding: 0 15px;
    .van-swipe-item {
        color: #fff;
        font-size: 20px;
        height: 168px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            height: 100%;
            width: 100%;
            border-radius: 15px;
        }
    }
    
}
</style>

