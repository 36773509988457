<template>
    <van-row class="navs" type="flex" justify="space-between">
        <van-col class="nav-item" span="4">
          <div class="img">
            <img src="@/assets/imgs/navs/nav_zhibo.png" alt="">
          </div>
            <span class="title">直播</span>
        </van-col>
        <van-col class="nav-item" span="4">
            <div class="img">
              <img src="@/assets/imgs/navs/nav_kaoshi.png" alt="">
            </div>
            <span class="title">考试</span>
        </van-col>
        <van-col class="nav-item" span="4">
            <div class="img">
              <img src="@/assets/imgs/navs/nav_lianxi.png" alt="">
            </div>
            <span class="title">练习</span>
        </van-col>
        <van-col class="nav-item" span="4">
            <div class="img">
              <img src="@/assets/imgs/navs/nav_tuwen.png" alt="">
            </div>
            <span class="title">图文</span>
        </van-col>
        <van-col class="nav-item" span="4">
            <div class="img">
              <img src="@/assets/imgs/navs/nav_lujing.png" alt="">
            </div>
            <span class="title">学习路径</span>
        </van-col>
    </van-row>
</template>

<script>
export default {
    name: 'Nav',
    components:{
    },
    methods:{
    }
}
</script>

<style lang="less" scoped>
.navs{
  background: #fff;
  padding: 15px;
  .nav-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .img {
          padding: 0 5px;
          img {
            width: 100%;
          }
      }
      .title {
        font-size: 12px;
        padding: 5px 0;
      }
  }
}
</style>