<template>
    <div class="home">
        <van-search v-model="searchKeyWords" placeholder="请输入搜索关键词" />
        <Banner />
        <Nav />
        <follow-mp />
        <lubo-list :courses="courses.lubos" />
        <zhibo-list :courses="courses.zhibos" />
        <tuwen-list :courses="courses.tuwens" />
        <Footer />
        <Tbar />
    </div>
</template>

<script>
import Banner from "../components/Banner.vue";
import Nav from "../components/Nav.vue";
import FollowMp from "../components/FollowMp.vue";
import LuboList from "../components/LuboList.vue";
import ZhiboList from "../components/ZhiboList.vue";
import TuwenList from "../components/TuwenList.vue";
import Footer from "../components/Footer.vue";
import Tbar from "../components/Tbar.vue";
import { Toast } from "vant";
// import {store} from 'xijs';
export default {
    name: "Home",
    data() {
        return {
            searchKeyWords: '',
            courses: {},
            userinfo: {},
        };
    },
    components: {
    Banner, Nav, Tbar, Footer,
    FollowMp, LuboList, ZhiboList, TuwenList
},
    mounted() {
        this.getCourses();
    },
    destroyed() {
    },
    methods: {
        getCourses(){
            this.$http.call(this.$http.api.courses).then(res=>{
                let resp = res.data;
                // console.log(resp);
                if(resp.code=='0'){
                    this.courses = resp.data;
                }else{
                    Toast(resp.data);
                } 
                
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            });
        }
    },
};
</script>
<style lang="less" scoped>
.home {
}
</style>