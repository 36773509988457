<template>
    <van-tabbar class="tbar" active-color="#f86f00" v-model="active" route>
        <van-tabbar-item replace to="/" icon="home-o">首页</van-tabbar-item>
        <van-tabbar-item replace to="/orders" icon="tv-o">学习</van-tabbar-item>
        <van-tabbar-item replace to="/user" icon="contact-o">我的</van-tabbar-item>
    </van-tabbar>
</template>

<script>
export default {
    name: 'Tbar',
    data() {
        return {
            active: 0,
        };
    },
    components:{
    },
    methods:{
    }
}
</script>

<style lang="less" scoped>
.tbar{
  font-weight: bold;
}
</style>